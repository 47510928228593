/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useRef } from "react";

// third-party modules
import { SpeedWork as SpeedWorkV3 } from "@onehq/anton";
import Userfront from "@userfront/core";

// app modules
import { WorkProps } from "./types";

// texts are beign send in batches now
const BATCH_SIZE = 100;

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-floating-promises
export const accessToken = Userfront.tokens.accessToken;

// these 3 values will change really fast, so we will remove them from status
interface SendingStatus {
  batch: string[];
}

const SpeedWork = ({
  currentWork,
  message,
  onNext,
  sendTexts,
  setVariant,
  setMessage,
  imageUrl,
  isMobile
}: WorkProps) => {
  // sendstatus will persist and only it's values will change
  // note that sendstatus and batch should never change their references (using =)
  // so that we can always change their inner attributes
  // and accessing them with their updated values
  const sendStatus = useRef<SendingStatus>({ batch: [] });
  // timer used to check if user is clicking "send" too fast
  const batchTimer = useRef<NodeJS.Timeout>();

  const onTextChange = (value: string) => {
    setMessage(value);
  };

  const onSend = (force?: boolean) => {
    // get current values
    const batch = sendStatus.current.batch;
    const currItemIdx = currentWork?.currItemIdx;
    const currentItem = currentWork?.currentItem;
    if (!currentWork || !currentItem || currItemIdx === undefined) return;

    // when force is false, we are not adding texts anymore. we just send
    if (!force) batch.push(currentItem.text.id);

    const maxSize = batch.length === BATCH_SIZE;
    const endOfPage = currItemIdx === currentWork.items.length - 1;
    if (batch.length && (force || maxSize || endOfPage)) {
      void sendTexts({
        fetchPolicy: "no-cache",
        variables: {
          projectId: currentWork.projectId,
          textIds: [...batch],
          message
        }
      });
      batch.splice(0, BATCH_SIZE);
    }

    if (!force) onNext();
  };
  // down here we handle events of the "send" button
  const onSendClick = () => {
    if (batchTimer.current) clearTimeout(batchTimer.current);

    batchTimer.current = setTimeout(() => {
      batchTimer.current = undefined;
      onSend(true);
    }, 500);

    onSend();
  };

  if (!currentWork) return <></>;
  // if pathname is none of these, dont show work
  return (
    <SpeedWorkV3
      work={{ ...currentWork }}
      hasNext
      totalItems={currentWork.paginationInfo.total}
      method="message"
      editorText={message}
      onEditorChange={onTextChange}
      placeholders={[]}
      onSend={onSendClick}
      onClose={() => setVariant("default")}
      imageUrl={imageUrl}
      mobile={isMobile}
    />
  );
};

export default SpeedWork;
