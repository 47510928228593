// react modules
import React, { useEffect } from "react";

// third-party modules
import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

// app modules
import {
  GetProjectsListQuery,
  GetUsersListQuery,
  GetTextsListQuery,
  useGetUsersListLazyQuery,
  useGetProjectTeamsListQuery,
  useGetProjectsListLazyQuery,
  useGetTextsListLazyQuery,
  ProjectTeamQueryFilterFields,
  FilterOperation,
  GetUsersListQueryVariables,
  GetProjectsListQueryVariables,
  GetTextsListQueryVariables
} from "../../../generated/graphql";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  UserTableColumns,
  userTableDataFormatter
} from "../../../components/User/UserTable";
import {
  TEAM_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  teamTabFilters
} from "../../../constants/teamConstants";
import DataOverview from "../../../components/pages/DataOverview";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

interface TeamShowProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const TeamShow = ({ id, ...props }: TeamShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [, { data: usersData, loading: loadingUsers, refetch: refetchUsers }] =
    useGetUsersListLazyQuery({
      fetchPolicy: "no-cache",
      variables: teamTabFilters({ id, selectedTab: VIEW_LINKS.USERS })
        .variables as GetUsersListQueryVariables,
      onCompleted: (response: GetUsersListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages && props.setTotalPages(response.users.totalCount);
      }
    });

  const handleUsersRefetch = (options: GetUsersListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchUsers(options);
  };

  // getting project teams related to this team
  const { data, loading, error } = useGetProjectTeamsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: ProjectTeamQueryFilterFields.TeamId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const hasValues = !!data && !loading && !error && !!data.projectTeams?.nodes;
  const projectsOnTeam = hasValues
    ? data.projectTeams?.nodes?.map(pt => pt?.projectId as string)
    : [];

  useEffect(() => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      projectsOnTeam: projectsOnTeam || []
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // getting projects to show, using the projectTexters list to make the filters
  const [
    ,
    { data: projectsData, loading: loadingProjects, refetch: refetchProjects }
  ] = useGetProjectsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: teamTabFilters({
      id,
      projectsOnTeam,
      selectedTab: VIEW_LINKS.PROJECTS
    }).variables as GetProjectsListQueryVariables,
    onCompleted: (response: GetProjectsListQuery) => {
      props.setTotalPages && props.setTotalPages(response.projects.totalCount);
    }
  });

  const handleProjectsRefetch = (options: GetProjectsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchProjects(options);
  };

  // getting texts to show, using the text list to make the filters
  const [, { data: textsData, loading: loadingTexts, refetch: refetchTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: teamTabFilters({
        id,
        projectsOnTeam,
        selectedTab: VIEW_LINKS.TEXTS
      }).variables as GetTextsListQueryVariables,
      onCompleted: (response: GetTextsListQuery) => {
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const handleTextsRefetch = (options: GetTextsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchTexts(options);
  };

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {props.activeLinkId === VIEW_LINKS.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={handleProjectsRefetch}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.USERS && (
        <List
          accessor="users"
          columns={UserTableColumns}
          data={usersData}
          dataFormat={userTableDataFormatter}
          loading={loadingUsers}
          refetchQuery={handleUsersRefetch}
        />
      )}
    </>
  );
};

export default TeamShow;
