// react modules
import React from "react";

// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  ClientFieldsFragment,
  ListFilter,
  useCreateClientMutation,
  useGetClientQuery,
  useNewClientQuery,
  useUpdateClientMutation
} from "../../generated/graphql";
import {
  CLIENTS_PATH,
  CLIENT_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import ClientAnchor from "./ClientAnchor";
import ListPage from "../../components/pages/ListPage";
import sections, {
  ClientFieldsWithSearchAgencyInput,
  ClientGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import ClientShow from "./ClientShow";
import { ExportButton } from "../../components/Actions/Export";
import { addSpacesBetweenWords, tableValuesToArray } from "../../utils";

const ClientsRoute = new ResourceRoute({
  name: "Client",
  path: CLIENTS_PATH,
  AnchorComponent: ClientAnchor,
  IndexComponent: () => <ListPage variant="Clients" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Client",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Agencies",
      path: "clients",
      baseRoute: "Client",
      actions: (
        <ExportButton baseResource={"Client"} variant={VIEW_LINKS.AGENCIES} />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.AGENCIES} />
      )
    }),
    new NestedResourceRoute({
      name: "Projects",
      path: "projects",
      baseRoute: "Client",
      actions: (
        <ExportButton baseResource={"Client"} variant={VIEW_LINKS.PROJECTS} />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.PROJECTS} />
      )
    })
  ],
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewClientQuery, "newClient"],
    // @ts-ignore
    query: [useGetClientQuery, "client"],
    // @ts-ignore
    update: [useUpdateClientMutation, "updateClient"],
    // @ts-ignore
    create: [useCreateClientMutation, "createClient"],
    // @ts-ignore
    useInitialValues: (client: ClientFieldsFragment) => {
      // maybe get client.phones and set then to both client and purchased tables
      const listFilters = client?.listFilters;

      const listFiltersAttributes = {};
      listFilters?.forEach((b, index) => {
        listFiltersAttributes[`id-${index}`] = b.id;
        listFiltersAttributes[`listFilterType-${index}`] = {
          label: addSpacesBetweenWords(b.listFilterType),
          value: b.listFilterType
        };
        listFiltersAttributes[`_destroy-${index}`] = false;
      });

      const initialValues = {
        searchAgencyId: client?.agencyId
          ? {
              label: client.agency?.name,
              value: client.agencyId
            }
          : undefined,
        listFiltersTable:
          listFilters?.map(
            ({
              id,
              listFilterType
            }): Partial<ListFilter> & { actions: boolean } => {
              return { id, listFilterType, actions: true };
            }
          ) || [],
        listFiltersAttributes
      };

      return initialValues;
    },
    // @ts-ignore
    normalize: (client: ClientFieldsWithSearchAgencyInput) => {
      if (!client) return client;

      Object.keys(client)
        .filter(k => k.includes("campaignId-"))
        .forEach(k => delete client[k]);

      const newAttr = { ...client, agencyId: client.searchAgencyId };

      if (client.listFiltersAttributes) delete client.listFiltersAttributes.id;

      newAttr.listFilters = tableValuesToArray(client.listFiltersAttributes, [
        "id",
        "listFilterType",
        "_destroy"
      ]).filter(listFilter => listFilter.listFilterType);

      return {
        ..._.omit(newAttr, formOmitValues)
      };
    }
  }
});

export { ClientsRoute };
