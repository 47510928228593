import React from "react";

import { LinkId } from "@onehq/anton";

import {
  CAMPAIGN_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  campaignTabFilters
} from "../../../constants/campaignConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  GetPhonesListQueryVariables,
  useGetPhonesListLazyQuery
} from "../../../generated/graphql";
import { List } from "@onehq/framework";
import { phoneTableDataFormatter } from "../../../components/Phone/PhoneTable";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

const campaignPhonesColumns = [
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "phoneStatus",
    header: "Status",
    accessorKey: "phoneStatus"
  }
];

interface CampaignProps {
  id: string;
  activeLinkId: LinkId;
}

const CampaignShow = ({ id, ...props }: CampaignProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();
  const [, { data, loading, refetch: refetchPhones }] =
    useGetPhonesListLazyQuery({
      fetchPolicy: "cache-and-network",
      variables: campaignTabFilters({ id, selectedTab: VIEW_LINKS.PHONES })
        .variables
    });

  const handleRefetchPhones = (options: GetPhonesListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchPhones(options);
  };

  switch (props.activeLinkId) {
    case VIEW_LINKS.OVERVIEW:
      return <DataOverview />;
    case VIEW_LINKS.PHONES:
      return (
        <List
          accessor="phones"
          columns={campaignPhonesColumns}
          data={data}
          dataFormat={phoneTableDataFormatter}
          loading={loading}
          refetchQuery={handleRefetchPhones}
        />
      );
    default:
      return <></>;
  }
};

export default CampaignShow;
