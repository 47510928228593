import React from "react";
import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  GetRowsListQueryVariables,
  GetProjectsListQueryVariables,
  useGetRowsListLazyQuery,
  useGetProjectsListLazyQuery
} from "../../../generated/graphql";
import {
  LIST_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  listTabFilters
} from "../../../constants/listConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  getRowTableColumns,
  rowTableDataFormatter
} from "../../../components/Row/RowTable";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

interface ListShowProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ListShow = ({ id, ...props }: ListShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  // generating rows fetch query
  const [, { data: rowData, loading: rowLoading, refetch: refetchRows }] =
    useGetRowsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: listTabFilters({ id, selectedTab: LIST_VIEW.ROWS })
        .variables as GetRowsListQueryVariables,
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response?.rows?.totalCount);
      }
    });

  const handleRowsRefetch = (options: GetRowsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchRows(options);
  };

  // generating projects fetch query
  const [
    ,
    { data: projectData, loading: projectLoading, refetch: refetchProjects }
  ] = useGetProjectsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: listTabFilters({ id, selectedTab: LIST_VIEW.PROJECTS })
      .variables as GetProjectsListQueryVariables,
    onCompleted: response => {
      props.setTotalPages &&
        props.setTotalPages(response?.projects?.totalCount);
    }
  });

  const handleProjectRefetch = (options: GetProjectsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchProjects(options);
  };

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === LIST_VIEW.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {props.activeLinkId === LIST_VIEW.ROWS && (
        <List
          accessor="rows"
          columns={getRowTableColumns()}
          data={rowData}
          dataFormat={rowTableDataFormatter}
          loading={rowLoading}
          refetchQuery={handleRowsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectData}
          dataFormat={projectTableDataFormatter}
          loading={projectLoading}
          refetchQuery={handleProjectRefetch}
        />
      )}
    </>
  );
};

export default ListShow;
