import React from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  GetTextsListQuery,
  useGetTextsListLazyQuery,
  GetTextsListQueryVariables
} from "../../../generated/graphql";
import {
  PHONE_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  phoneTabFilters
} from "../../../constants/phoneConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

interface PhoneShowProps {
  id: string;
  setTotalPages?: Function;
  activeLinkId: LinkId;
}

const PhoneShow = ({ id, ...props }: PhoneShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [, { data: textsData, loading: loadingTexts, refetch: refetchTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: phoneTabFilters({
        id,
        selectedTab: props.activeLinkId as string
      }).variables as GetTextsListQueryVariables,
      onCompleted: (response: GetTextsListQuery) => {
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const handleTextsRefetch = (options: GetTextsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchTexts(options);
  };

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  return (
    <>
      {props.activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {props.activeLinkId === LIST_VIEW.SENT_TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === LIST_VIEW.RECEIVED_TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
    </>
  );
};

export default PhoneShow;
