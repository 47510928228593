/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ProjectListFieldsFragment } from "../../generated/graphql";
import {
  badgeCell,
  BadgeTheme,
  CellDataType,
  noWrapCell,
  Table
} from "@onehq/anton";
import {
  addSpacesBetweenWords,
  formatDate,
  formatPhone,
  projectStatusBadgeColor
} from "../../utils";
import { Link } from "@onehq/framework";
import { BaseTableProps } from "../../types";
import { PROJECTS_PATH } from "../../constants";

export type ProjectTableProps = BaseTableProps<ProjectListFieldsFragment>;

export const ProjectTableColumns = [
  {
    header: "Number",
    accessorKey: "number",
    type: "number"
  },
  {
    header: "State",
    accessorKey: "state",
    disableSortBy: true
  },
  {
    header: "Name",
    accessorKey: "name"
  },
  {
    header: "Message",
    accessorKey: "message",
    dataType: "longText" as CellDataType,
    cell: noWrapCell,
    columnMaxWidth: 200,
    tooltipMaxLength: 60
  },
  {
    header: "Start At",
    accessorKey: "startAt"
  },
  {
    header: "List Count",
    accessorKey: "listCount",
    type: "number"
  },
  {
    header: "Client",
    accessorKey: "client",
    disableSortBy: true
  },
  {
    header: "Type",
    accessorKey: "projectTypeId",
    disableSortBy: true
  },
  {
    header: "Status",
    accessorKey: "projectStatusId",
    disableSortBy: true
  },
  {
    header: "Phones",
    accessorKey: "projectPhones",
    disableSortBy: true,
    cell: noWrapCell,
    columnMaxWidth: 20,
    tooltipMaxLength: 10
  }
];

export const projectTableDataFormatter = (
  data?: Array<ProjectListFieldsFragment>
) => [
  ...(data?.map((item: ProjectListFieldsFragment) => {
    const {
      client,
      listCount,
      message,
      phones,
      name,
      number,
      status,
      type,
      state,
      startAt
    } = item;

    return {
      client: (
        <Link to={`/${PROJECTS_PATH}/${client.id}/overview`}>
          {client.name}
        </Link>
      ),
      listCount,
      name: <Link to={`/${PROJECTS_PATH}/${item.id}/overview`}>{name}</Link>,
      message,
      number,
      projectStatusId: badgeCell({
        value: addSpacesBetweenWords(status),
        color: projectStatusBadgeColor(status) as BadgeTheme
      }),
      projectPhones: phones
        ?.map(el => formatPhone(el.number.toString()))
        .join(", "),
      projectTypeId: addSpacesBetweenWords(type),
      startAt: formatDate(startAt),
      state: state?.name
    };
  }) || [])
];

const ProjectTable = ({ data = [], ...props }: ProjectTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ProjectTableColumns}
      data={projectTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ProjectTable;
