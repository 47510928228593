// react modules
import React, { useMemo } from "react";

// third-party modules
import {
  Fieldset,
  HiddenField,
  SearchField,
  Table,
  useGetSubmitForm,
  useSetFieldValue
} from "@onehq/anton";
import { CellContext } from "@tanstack/react-table";
import { ListFilter, ListFilterType } from "../../generated/graphql";
import { NoPaddingButton } from "../../pages/clients/sections/index.styles";
import { addSpacesBetweenWords } from "../../utils";

const listFilterTypes = Object.keys(ListFilterType);

interface ListFilterFieldsProps {
  listFilters: Partial<ListFilter>[];
}

const ListFilterFields = ({ listFilters }: ListFilterFieldsProps) => {
  const setValue = useSetFieldValue();
  const submitForm = useGetSubmitForm();

  const listFilterOptions = useMemo(() => {
    return listFilterTypes
      .filter(listFilterType => {
        return !listFilters.find(
          listFilter => listFilter.listFilterType === listFilterType
        );
      })
      .map(listFilterType => ({
        label: addSpacesBetweenWords(listFilterType),
        value: listFilterType
      }));
  }, [listFilters]);

  const ListFilterFieldCell = useMemo(
    () => {
      return ({ row, getValue }: CellContext<any, any>) => {
        // current row is edit or new brand config
        const isDisabled = !!getValue();
        return (
          <>
            <HiddenField name={`id-${row.index}`} />
            {/* @ts-ignore */}
            <SearchField
              name={`listFilterType-${row.index}`}
              isTableInput
              collapseLabel
              placeholder="Select a Filter"
              disabled={isDisabled}
              defaultOptions={listFilterOptions}
              loadOptions={(text: string) => {
                return Promise.resolve(
                  listFilterOptions.filter(o => {
                    return (
                      o.label.toLowerCase().search(text.toLowerCase()) >= 0
                    );
                  })
                );
              }}
            />
          </>
        );
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listFilterOptions]
  );

  const ActionCell = useMemo(
    () =>
      ({ row, getValue }: CellContext<any, any>) => {
        // get id value to know if this cell is a new brand config or a existent one
        const isDestroyable = getValue();

        const destroyListFilter = () => {
          setValue(`_destroy-${row.index}`, true);
          !!submitForm && submitForm();
        };
        return (
          <>
            {/* This is to keep id value into the form values */}
            <HiddenField name={`id-${row.index}`} />
            {/* This is to keep destroy value into the form values */}
            <HiddenField name={`_destroy-${row.index}`} />
            {/* if this brand config exists, show delete brand config action */}
            {isDestroyable && (
              <NoPaddingButton variant="inline" onClick={destroyListFilter}>
                Delete
              </NoPaddingButton>
            )}
          </>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = [
    {
      id: "listFilterType",
      header: "Exlcuded Phones",
      accessorKey: "listFilterType",
      columnMinWidth: 500,
      cell: ListFilterFieldCell,
      size: 200, // starting column size
      minSize: 50, // enforced during column resizing
      maxSize: 500 // enforced during column resizing
    },
    {
      id: "actions",
      accessorKey: "actions",
      header: "Actions",
      cell: ActionCell
    }
  ];

  return (
    <Fieldset>
      <Table
        variant="data-grid"
        columns={columns}
        data={listFilters}
        setData={() => null}
      />
    </Fieldset>
  );
};

export default ListFilterFields;
