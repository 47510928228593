// react modules
import React, { useRef } from "react";

// third-party modules
import styled from "styled-components";
import { theme } from "@onehq/style";
import { Dialog as DefaultDialog, Icon, FormBuilder } from "@onehq/anton";

// app modules
import {
  BasePhoneFieldsFragment,
  BaseTextFieldsFragment,
  MediaPosition,
  PhoneStatus,
  ProjectFieldsFragment
} from "../../../generated/graphql";
import TestTextFormFields from "./TestTextFormFields";
import { formatPhone, formatPhoneOption } from "../../../utils";
import { htmlToString } from "..";

const { neutral50 } = theme.color;
const { radius } = theme.border;
const { spacing24 } = theme.space;
const { base, md, lg } = theme.font;

const Overlay = styled.div`
  background-color: rgba(31, 46, 76, 0.2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const Root = styled.div`
  align-items: center;
  display: flex;
  font-size: ${base.size}; /* defaults the font size to base */
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const Dialog = styled(DefaultDialog)`
  border-radius: ${radius.lg};
  position: fixed;
  padding: ${spacing24};
  max-width: 1000px;
  max-height: 80%;
  width: 70%;
  overflow: auto;
  z-index: 4;
`;

const DialogBody = styled.div`
  position: relative;
`;

const Title = styled.div`
  color: ${neutral50};
  font-size: ${lg.size};
  font-weight: ${md.type.medium.fontWeight};
  line-height: 1;
  padding-bottom: ${spacing24};
`;

const HorizontalSections = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const SpacedRow = styled(Row)`
  justify-content: space-between;
`;

const MaxWidth = styled.div`
  width: 100%;
`;

export interface ProjectTestTextsProps {
  projectName: string;
  message: string;
  projectMediaUrl?: string | null;
  projectMediaPosition?: MediaPosition | null;
  managerPhones: ProjectFieldsFragment["manager"]["recipientPhones"];
  clientPhones: ProjectFieldsFragment["client"]["clientRecipientPhones"];
  alreadySelectedPhone?: BaseTextFieldsFragment["toPhone"];
  fromPhones?: Partial<BasePhoneFieldsFragment>[];
  projectMessage?: string;
  onSend: (phoneTo: string[], body: string, fromPhoneId: string) => void;
  onClose: () => void;
}

const ProjectTestTexts = ({
  projectName,
  message,
  projectMediaUrl,
  projectMediaPosition,
  managerPhones,
  clientPhones,
  alreadySelectedPhone,
  projectMessage,
  fromPhones,
  onSend,
  onClose
}: ProjectTestTextsProps) => {
  const refForm = useRef<HTMLFormElement>(null);

  const submit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  const sendText = (values: any) => {
    const phoneTo = values.phoneId as string[];
    const message = htmlToString(values.message as string);
    const fromPhoneId = values.fromPhoneId as string;
    onSend(phoneTo, message, fromPhoneId);
    return Promise.resolve();
  };

  const defaultFromPhone = fromPhones?.find(
    p => p.phoneStatus === PhoneStatus.Active
  );

  return (
    <Root>
      <Dialog open={true}>
        <DialogBody>
          <FormBuilder
            ref={refForm}
            values={{
              message,
              mediaUrl: projectMediaUrl,
              mediaPosition: projectMediaPosition,
              fromPhoneId: defaultFromPhone
                ? formatPhoneOption(defaultFromPhone)
                : null,
              phoneId: alreadySelectedPhone
                ? [
                    {
                      label: formatPhone(alreadySelectedPhone.number),
                      value: alreadySelectedPhone.id
                    }
                  ]
                : null
            }}
            onSubmit={sendText}
            autosave={false}
          >
            <MaxWidth>
              <Title>
                <SpacedRow>
                  <span>{projectName}</span>
                  <IconButton onClick={onClose}>
                    <Icon name="x" fill="neutral70" />
                  </IconButton>
                </SpacedRow>
              </Title>

              <HorizontalSections>
                <TestTextFormFields
                  managerPhones={managerPhones}
                  clientPhones={clientPhones}
                  alreadySelectedPhone={alreadySelectedPhone}
                  projectMessage={projectMessage}
                  fromPhones={fromPhones}
                  onSubmit={submit}
                />
              </HorizontalSections>
            </MaxWidth>
          </FormBuilder>
        </DialogBody>
      </Dialog>
      <Overlay onClick={onClose} />
    </Root>
  );
};

export default ProjectTestTexts;
