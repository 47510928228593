import { FormSection } from "@onehq/framework";

import OrganizationGeneralForm from "./OrganizationGeneralForm";
import TextDefaultForm from "./TextDefaultForm";
import CustomDomainsForm from "./CustomDomainsForm";
import ListFiltersForm from "../../../../components/ListFilter/ListFiltersForm";

export default [
  new FormSection("General", OrganizationGeneralForm),
  new FormSection("Text Defaults", TextDefaultForm),
  new FormSection("Domains", CustomDomainsForm),
  new FormSection("Filters", ListFiltersForm)
];
