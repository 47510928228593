import {
  Button as DefaultButton,
  DateField,
  Fieldset,
  FormBuilder,
  Pagination
} from "@onehq/anton";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useGenerateCsv } from "../../components/generateCsv";
import {
  AuditReport,
  AuditReportDocument,
  useAuditReportQuery
} from "../../generated/graphql";
import AuditTable from "./auditReportTable";
import { GrowlAlertDispatch, useDispatchGrowlContext } from "@onehq/framework";
import { addErrorAlert } from "../../utils";

const PAGE_SIZE = 15;

const Wrapper = styled.div`
  display: flex;

  & > form {
    margin: 0 -8px;
    padding: 0;
  }
`;
const Button = styled(DefaultButton)`
  align-self: end;
  margin: ${props => props.theme.space.spacing8};
  margin-right: 0;
`;
const PaginationContainer = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: center;
`;

// converts input's date (mm / dd / yyyy) to standart format (yyyy-mm-dd)
function format(str = "") {
  const parts = str.split(" / ");
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

const AuditReportForm = () => {
  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
  const [page, setPage] = useState(1);
  const ref = useRef<HTMLFormElement>();
  const [disableExport, setDisableExport] = useState(false);

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const { data, loading, refetch } = useAuditReportQuery({
    fetchPolicy: "cache-and-network",
    variables: { startDate: today, endDate: today }
  });
  const rows = useMemo(() => {
    return (
      (data?.auditReport.nodes?.filter(n => n !== null) as AuditReport[]) || []
    );
  }, [data]);
  const paginatedRows = useMemo(() => {
    const start = (page - 1) * PAGE_SIZE;
    return rows.slice(start, start + PAGE_SIZE);
  }, [rows, page]);

  const { csvButtonProps, csvLink } = useGenerateCsv({
    disable: rows.length === 0,
    apolloDocument: AuditReportDocument,
    filename: "audit-report"
  });

  const areDatesValid = useMemo(() => {
    return (startDate: string, endDate: string) => {
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          const message = "Start date must not be greater than the end date";
          addErrorAlert(alert, "Invalid date", message);
          setDisableExport(true);
          return false;
        }
      } else setDisableExport(true);
      setDisableExport(false);
      return true;
    };
  }, [alert]);

  const onSubmit = ({ startDate = "", endDate = "" }) => {
    if (areDatesValid(startDate, endDate)) {
      setPage(1);
      void refetch({ startDate, endDate });
    }
    return Promise.resolve();
  };

  const onExportClick = () => {
    const form: HTMLFormElement | undefined = ref.current?.formRef.current;
    if (form && csvButtonProps.onClick) {
      const startDate = (form[0] as HTMLInputElement)?.value;
      const endDate = (form[1] as HTMLInputElement)?.value;
      if (startDate && endDate) {
        csvButtonProps.onClick({
          startDate: format(startDate),
          endDate: format(endDate)
        });
      } else {
        addErrorAlert(alert, "Invalid date", "Start and end date required");
      }
    }
  };

  return (
    <>
      <>{csvLink}</>
      <Wrapper>
        <FormBuilder
          values={{ startDate: today, endDate: today }}
          onSubmit={onSubmit}
          ref={ref}
        >
          <Fieldset>
            <DateField label="Start Date" name="startDate" required />
            <DateField label="End Date" name="endDate" required />
          </Fieldset>
        </FormBuilder>
        <Button
          {...csvButtonProps}
          disabled={disableExport}
          onClick={onExportClick}
        >
          Export
        </Button>
      </Wrapper>
      <AuditTable data={paginatedRows} loading={loading} />
      <PaginationContainer>
        <Pagination
          currentPage={page}
          onPageClick={page => setPage(page)}
          totalPages={Math.ceil(rows.length / PAGE_SIZE)}
        />
      </PaginationContainer>
    </>
  );
};

export default AuditReportForm;
