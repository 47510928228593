// react modules
import React, { useMemo } from "react";

// third-party modules
import { Fieldset, NestedForm, useWatchContext } from "@onehq/anton";
import ListFilterFields from "./ListFilterFields";
import { ListFilter } from "../../generated/graphql";

const ListFiltersForm = () => {
  const listFilters: ListFilter[] = useWatchContext("listFiltersTable");

  const data: Partial<ListFilter>[] = useMemo(() => {
    return [...listFilters, { id: "", listFilterType: undefined }];
  }, [listFilters]);

  return (
    <Fieldset>
      <NestedForm
        name="listFiltersAttributes"
        component={() => <ListFilterFields listFilters={data} />}
        hasOne
      />
    </Fieldset>
  );
};

export default ListFiltersForm;
