import React from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  GetClientsListQuery,
  GetClientsListQueryVariables,
  GetProjectsListQuery,
  GetProjectsListQueryVariables,
  useGetClientsListLazyQuery,
  useGetProjectsListLazyQuery
} from "../../../generated/graphql";
import {
  CLIENT_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  clientTabFilters
} from "../../../constants/clientConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  ClientTableColumns,
  clientTableDataFormatter
} from "../../../components/Client/ClientTable";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

interface ClientViewProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ClientShow = ({ id, ...props }: ClientViewProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [
    ,
    { data: clientsData, loading: loadingClients, refetch: refetchClients }
  ] = useGetClientsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: clientTabFilters({ id, selectedTab: VIEW_LINKS.AGENCIES })
      .variables as GetClientsListQueryVariables,
    onCompleted: (response: GetClientsListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.clients.totalCount);
    }
  });

  const handleClientRefetch = (options: GetClientsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchClients(options);
  };

  const [
    ,
    { data: projectsData, loading: loadingProjects, refetch: refetchProjects }
  ] = useGetProjectsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: clientTabFilters({ id, selectedTab: VIEW_LINKS.PROJECTS })
      .variables as GetProjectsListQueryVariables,
    onCompleted: (response: GetProjectsListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.projects.totalCount);
    }
  });

  const handleProjectRefetch = (options: GetProjectsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchProjects(options);
  };

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  switch (props.activeLinkId) {
    case VIEW_LINKS.OVERVIEW:
      return <DataOverview />;
    case VIEW_LINKS.AGENCIES:
      return (
        <List
          accessor="clients"
          columns={ClientTableColumns}
          data={clientsData}
          dataFormat={clientTableDataFormatter}
          loading={loadingClients}
          refetchQuery={handleClientRefetch}
        />
      );
    case VIEW_LINKS.PROJECTS:
      return (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={handleProjectRefetch}
        />
      );
    default:
      return <></>;
  }
};

export default ClientShow;
