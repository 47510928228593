import React from "react";

import { LinkId } from "@onehq/anton";

import {
  CustomDomainListFieldsFragment,
  GetCampaignsListQueryVariables,
  GetCustomDomainsListQueryVariables,
  GetTextDefaultsListQueryVariables,
  ListFilterListFieldsFragment,
  useGetCampaignsListLazyQuery,
  useGetCustomDomainsListLazyQuery,
  useGetListFiltersListQuery,
  useGetTextDefaultsListLazyQuery
} from "../../../generated/graphql";
import {
  ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  organizationTabFilters
} from "../../../constants";
import { addSpacesBetweenWords, PAGE_SIZE } from "../../../utils";
import DataOverview from "../../../components/pages/DataOverview";
import { List } from "@onehq/framework";
import {
  TextDefaultTableColumns,
  textDefaultTableDataFormatter
} from "../../../components/TextDefault/TextDefaultTable";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../../../components/Campaign/CampaignTable";
import AuditReportForm from "../../auditReport/auditReport";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

export const DomainTableColumns = [
  {
    header: "Domain",
    accessorKey: "domain",
    disableSortBy: true
  }
];

export const FiltersTableColumns = [
  {
    header: "Excluded Phones",
    accessorKey: "filter",
    disableSortBy: true
  }
];

export const DomainTableDataFormatter = (
  data?: Array<CustomDomainListFieldsFragment>
) => [
  ...(data?.map((item: CustomDomainListFieldsFragment) => {
    return { domain: item.domain };
  }) || [])
];

export const ListFilterTableDataFormatter = (
  data?: Array<ListFilterListFieldsFragment>
) => [
  ...(data?.map((item: ListFilterListFieldsFragment) => {
    return { filter: addSpacesBetweenWords(item.listFilterType) };
  }) || [])
];

const campaignsTableColumns = CampaignTableColumns.filter(
  c => c.accessorKey !== "code"
);

interface OrganizationShowProps {
  id: string;
  activeLinkId: LinkId;
}

const OrganizationShow = ({ activeLinkId, id }: OrganizationShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [
    ,
    {
      data: textDefaultsData,
      loading: loadingTextDefaults,
      refetch: refetchTextDefaults
    }
  ] = useGetTextDefaultsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.TEXT_DEFAULTS
    }).variables as GetTextDefaultsListQueryVariables
  });

  const handleTextDefaultsRefetch = (
    options: GetTextDefaultsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchTextDefaults(options);
  };

  const [
    ,
    {
      data: domainsData,
      loading: loadingDomains,
      refetch: refetchCustomDomains
    }
  ] = useGetCustomDomainsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({ id, selectedTab: LIST_VIEW.DOMAINS })
      .variables as GetCustomDomainsListQueryVariables
  });

  const handleCustomDomainsRefetch = (
    options: GetCustomDomainsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchCustomDomains(options);
  };

  const [
    ,
    {
      data: campaignsData,
      loading: loadingCampaigns,
      refetch: refetchCampaigns
    }
  ] = useGetCampaignsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.UNREGISTERED_CAMPAIGNS
    }).variables as GetCampaignsListQueryVariables
  });

  const handleCampaignsRefetch = (options: GetCampaignsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchCampaigns(options);
  };

  const {
    data: filtersData,
    loading: loadingFilters,
    refetch: refetchFilters
  } = useGetListFiltersListQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE
    }
  });

  return (
    <>
      {activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {activeLinkId === LIST_VIEW.TEXT_DEFAULTS && (
        <List
          accessor="textDefaults"
          columns={TextDefaultTableColumns}
          data={textDefaultsData}
          dataFormat={textDefaultTableDataFormatter}
          loading={loadingTextDefaults}
          refetchQuery={handleTextDefaultsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.DOMAINS && (
        <List
          accessor="customDomains"
          columns={DomainTableColumns}
          data={domainsData}
          dataFormat={DomainTableDataFormatter}
          loading={loadingDomains}
          refetchQuery={handleCustomDomainsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.UNREGISTERED_CAMPAIGNS && (
        <List
          accessor="campaigns"
          columns={campaignsTableColumns}
          data={campaignsData}
          dataFormat={campaignTableDataFormatter}
          loading={loadingCampaigns}
          refetchQuery={handleCampaignsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.AUDIT_REPORT && <AuditReportForm />}
      {activeLinkId === LIST_VIEW.FILTERS && (
        <List
          accessor="listFilters"
          columns={FiltersTableColumns}
          data={filtersData}
          dataFormat={ListFilterTableDataFormatter}
          loading={loadingFilters}
          refetchQuery={refetchFilters}
        />
      )}
    </>
  );
};

export default OrganizationShow;
