import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction
} from "react";
import { TextStatus } from "../generated/graphql";

interface ResourceTabsProps {
  projectsOnTeam: string[];
  projectTexters: string[];
  teamsInProject: string[];
  selectedTextType: string;
  after: string;
}

interface ResourceTabsProviderProps {
  value: ResourceTabsProps;
  setValue: Dispatch<SetStateAction<ResourceTabsProps>>;
}

const ResourceTabsContext = createContext<
  ResourceTabsProviderProps | undefined
>(undefined);

export const ResourceTabsProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [value, setValue] = useState<ResourceTabsProps>({
    projectsOnTeam: [],
    projectTexters: [],
    teamsInProject: [],
    selectedTextType: TextStatus.Active,
    after: ""
  });

  return (
    <ResourceTabsContext.Provider value={{ value, setValue }}>
      {children}
    </ResourceTabsContext.Provider>
  );
};

export const useResourceTabsProvider = () => {
  const context = useContext(ResourceTabsContext);
  if (!context) {
    throw new Error(
      "useResourceTabsProvider must be used within a ResourceTabsProvider"
    );
  }
  return context;
};
