import {
  CustomDomainQueryFilterFields,
  FilterOperation,
  GetCustomDomainsListCsvDocument,
  TextDefaultQueryFilterFields,
  GetTextDefaultsListCsvDocument,
  CampaignQueryFilterFields,
  GetCampaignsListCsvDocument
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const ORGANIZATIONS_PATH = "organization";

export const ORGANIZATION_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEXT_DEFAULTS: "Text Defaults",
  DOMAINS: "Domains",
  UNREGISTERED_CAMPAIGNS: "Unregistered Campaigns",
  AUDIT_REPORT: "Audit Report",
  FILTERS: "Filters"
};

export const organizationTabFilters = ({
  id,
  selectedTab
}: BaseFilterTabsProps) => {
  const filterOptions = {
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.DOMAINS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CustomDomainQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetCustomDomainsListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.TEXT_DEFAULTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextDefaultQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetTextDefaultsListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.UNREGISTERED_CAMPAIGNS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CampaignQueryFilterFields.OrganizationId,
            operation: FilterOperation.With,
            value: "false"
          }
        ]
      },
      listDocument: GetCampaignsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
