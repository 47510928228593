import React, { useState } from "react";

import _ from "lodash";
import {
  Form,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";

import {
  ListFilter,
  TextDefaultInput,
  UpdateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation
} from "../../../generated/graphql";
import {} from "../../../types";
import {
  addAlert,
  addSpacesBetweenWords,
  removeAlert,
  tableValuesToArray
} from "../../../utils";
import sections from "./sections";

export interface OrganizationEditProps {
  id: string;
}

const OrganizationEdit = ({ id }: OrganizationEditProps) => {
  const organizationAlert: GrowlAlertDispatch = useDispatchGrowlContext();
  const [alertID] = useState(Math.floor(Math.random() * 10000 + 1));

  const { data, loading } = useGetOrganizationQuery({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });

  const [updateOrganization] = useUpdateOrganizationMutation({
    onCompleted: (response: UpdateOrganizationMutation) => {
      if (Object.keys(response.updateOrganization?.errors || {}).length === 0) {
        addAlert(organizationAlert, "organization.changed");
      } else {
        console.error(response.updateOrganization?.errors); // the error if that is the case
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  const organizationValues = data?.organization;
  const listFilters = organizationValues?.listFilters.filter(
    l => !l.clientId && !l.projectId
  );
  const listFiltersAttributes = {};
  listFilters?.forEach((b, index) => {
    listFiltersAttributes[`id-${index}`] = b.id;
    listFiltersAttributes[`listFilterType-${index}`] = {
      label: addSpacesBetweenWords(b.listFilterType),
      value: b.listFilterType
    };
    listFiltersAttributes[`_destroy-${index}`] = false;
  });

  const values = {
    ...organizationValues,
    textDefaults: organizationValues?.textDefaults.map(
      ({ field, id, value }: TextDefaultInput) => ({
        id,
        field,
        valueField: value
      })
    ),
    customDomainsAttributes:
      organizationValues?.customDomains.map(({ id, domain }): any => {
        return { id, domain };
      }) || [],
    listFiltersTable:
      listFilters?.map(
        ({
          id,
          listFilterType
        }): Partial<ListFilter> & { actions: boolean } => {
          return { id, listFilterType, actions: true };
        }
      ) || [],
    listFiltersAttributes
  };

  return (
    <Form
      autosave
      // @ts-ignore
      sections={sections}
      skeleton={loading || !values}
      values={values}
      withSelectedSection
      // eslint-disable-next-line @typescript-eslint/require-await
      onSubmit={async (attr: any) => {
        addAlert(organizationAlert, "organization.save", alertID);

        const newAttr = _.omit(
          attr,
          "__typename",
          "timeZone",
          "customDomainsAttributes",
          "logotypeUrl",
          "listFiltersAttributes",
          "listFiltersTable"
        );

        newAttr.textDefaults = attr.textDefaults
          ?.filter(td => td.id || (td.field && td.valueField))
          .map((td: TextDefaultInput & { valueField: string }) => {
            const { id, field, valueField, X } = td;

            return {
              id: id || null,
              field,
              value: valueField,
              _destroy: !!(id && X)
            };
          });

        newAttr.customDomains = attr.customDomainsAttributes.map(
          ({ id, domain, X }): any => {
            return { id, domain, _destroy: !!(id && X) };
          }
        );

        delete attr.listFiltersAttributes.id;

        newAttr.listFilters = tableValuesToArray(attr.listFiltersAttributes, [
          "id",
          "listFilterType",
          "_destroy"
        ]).filter(listFilter => listFilter.listFilterType);

        await updateOrganization({
          variables: { id: newAttr.id, attributes: newAttr }
        }).then(() => {
          removeAlert(organizationAlert, alertID);
        });
      }}
    />
  );
};

export default OrganizationEdit;
