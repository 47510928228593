import React from "react";
import { ActionGroupProps, actionGroupFormat } from "@onehq/anton";
import { useGenerateCsv } from "../../generateCsv";
import { useIsMobile } from "@onehq/framework";
import { useParams } from "react-router-dom";
import {
  campaignTabFilters,
  clientTabFilters,
  listTabFilters,
  organizationTabFilters,
  phoneTabFilters,
  projectTabFilters,
  teamTabFilters,
  userTabFilters
} from "../../../constants";
import { BaseFilterTabsProps } from "../../../types";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

const filterVariants = {
  Campaign: campaignTabFilters,
  Client: clientTabFilters,
  List: listTabFilters,
  Organization: organizationTabFilters,
  Phone: phoneTabFilters,
  Project: projectTabFilters,
  Team: teamTabFilters,
  User: userTabFilters
};

type BaseResource = keyof typeof filterVariants;

interface FilterVariantDefaults {
  teamsInProject?: string[];
  selectedTextType?: string;
  projectsOnTeam?: string[];
  projectTexters?: string[];
}

type FilterVariantProps = BaseFilterTabsProps & FilterVariantDefaults;

interface ExportButtonProps {
  baseResource: BaseResource;
  variant: string;
}

export const ExportButton = ({ baseResource, variant }: ExportButtonProps) => {
  const params = useParams();
  const id = params.id || "";
  const isMobile = useIsMobile();
  const { value: resourceTabsContext } = useResourceTabsProvider();

  const selectedFiltersProps: FilterVariantProps = { id, selectedTab: variant };

  switch (baseResource) {
    case "Project":
      selectedFiltersProps.teamsInProject = resourceTabsContext.teamsInProject;
      selectedFiltersProps.selectedTextType =
        resourceTabsContext.selectedTextType;
      break;
    case "Team":
      selectedFiltersProps.projectsOnTeam = resourceTabsContext.projectsOnTeam;
      break;
    case "User":
      selectedFiltersProps.projectTexters = resourceTabsContext.projectTexters;
      break;
    default:
      break;
  }

  const selectedFilters = filterVariants[baseResource](
    selectedFiltersProps as BaseFilterTabsProps & FilterVariantDefaults
  );

  const { csvButtonProps, csvLink } = useGenerateCsv({
    apolloDocument: selectedFilters.listDocument,
    filename: variant,
    variables: {
      ...selectedFilters.variables,
      after: resourceTabsContext.after
    }
  });

  const tempActions: ActionGroupProps["actions"] = [
    {
      type: "button",
      name: "Export",
      actionProps: {
        ...csvButtonProps
      }
    }
  ];

  return (
    <>
      {csvLink}
      {actionGroupFormat({ actions: tempActions, mobile: isMobile })}
    </>
  );
};
